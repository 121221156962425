import Vue from 'vue'
import on from 'dom-event'
import Marquee3k from 'marquee3000'
import { MOBILE_BREAKPOINT } from '@/lib/consts'

const PAUSE_BTN_SELECTOR = '.js-pause-button'

export default el => new Vue({
  name: 'Marquee',
  el,
  data() {
    return {
      speed: 0,
      speedDesktop: parseFloat(el.getAttribute('data-speed')),
      speedMobile: parseFloat(el.getAttribute('data-speed-mobile')),
      key: 0,
    }
  },
  mounted() {
    this.getMarqueeSpeed()
    this.refreshOnWindowResize()

    setTimeout(() => {
      Marquee3k.init()
    })

    this.toggleButton()

  },
  methods: {
    getMarqueeSpeed() {
      if (window.innerWidth < MOBILE_BREAKPOINT) {
        this.speed = this.speedMobile
      } else {
        this.speed = this.speedDesktop
      }
    },
    refreshOnWindowResize() {
      let windowWidth = window.innerWidth
      const onWindowChange = () => {
        if (window.innerWidth !== windowWidth) {
          Marquee3k.pauseAll()

          windowWidth = window.innerWidth
          this.getMarqueeSpeed()
          this.key++ // Force to create new instance of marquee root

          setTimeout(() => {
            Marquee3k.init()
          })
        }
      }
      on(window, 'resize', onWindowChange)
      on(window, 'orientationchange', onWindowChange)
    },
    toggleButton() {
      const pauseButtonEl = this.$el.querySelector(PAUSE_BTN_SELECTOR)

      if (pauseButtonEl) {
        on(pauseButtonEl, 'click', () => {
          const value = pauseButtonEl.getAttribute('aria-pressed') === 'false' ? 'true' : "false"
          pauseButtonEl.setAttribute('aria-pressed', value)
          const spanEl = pauseButtonEl.querySelector('span')

          if (pauseButtonEl.getAttribute('aria-pressed') === 'false') {
            Marquee3k.pauseAll()
            spanEl.innerText('Pause Marque')
          } else {
            Marquee3k.playAll()
            spanEl.innerText('Play Marque')
          }
        })
      }
    }
  },
})